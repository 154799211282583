import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'event',
    component: () => import('../views/WeddingView.vue'),
    props: route => ({
      invitado: route.query.invitado,
      cantidad: route.query.cantidad,
      id: route.query.id
    }),
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

export default router
